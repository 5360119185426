import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import ParticlerWraper from "./particlerwraper";
import Footer from "./Footer";
import { useSuperfan  } from "../../superfancontext";

export const Wraper = ({ children, style, ...rest }) => {
  const { userMetaData } = useSuperfan();
  
  return (
    <ParticlerWraper>
      <motion.section
        style={{
          width: "100svw",
          maxWidth: "700px",
          margin: "0 auto",
          height: "100svh",
          backgroundColor: "#f4e3c9",
          touchAction: "none",
          overflow: "hidden",
          padding: "0 1rem",
          ...style,
        }}
        
        initial={{ opacity: 0 }}
        animate={{ opacity: userMetaData ? 1 : 0 , transition: { duration: 0.5}}}
        //animate={{ opacity: 1  , transition: { duration: 0.5}}}
        {...rest}
      >
        {children}
        <Footer />
      </motion.section>
    </ParticlerWraper>
  );
};

export const WraperWhite = ({ children, style, ...rest }) => {
  return (
    <motion.section
      style={{
        width: "100svw",
        height: "100svh",
        backgroundColor: "white",
        touchAction: "none",
        ...style,
      }}
      {...rest}
    >
      {children}
    </motion.section>
  );
};

export const Button = ({ children, style, onClick, ...rest }) => {
  return (
    <button
    id="btn1"
      style={{
        textDecoration: "none",
        fontFamily: "HelveticaBtn",
        height: "2.75rem",
        width: "11rem",
        display: "block",
        margin: "0 auto",
        backgroundColor: "#f4e3c9",
        backgroundImage: "url(webp/btn01_1.webp)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "400% 200%",
        backgroundPosition: '200% 0%',
        transition: "all 1s",
        fontWeight: "500", //Check after applying new fontFamily
        border: "black 2px solid",
        color: "black",
        position: "relative",
        fontSize: "0.75rem",
        fontWeight: "bold",
        paddingTop: "0.1rem",
        zIndex: "2",

        ...style,
      }}
      onClick={(e)=>{
        const target= e.target;
        target.style.backgroundPosition = "0% 0%";
        target.style.color = "white";
        setTimeout(() => {
          target.style.backgroundPosition = "200% 0%";
          target.style.color = "black";
          if (onClick) onClick();
        }, 1000);
        
      }}
      {...rest}
    >
      {children}
    </button>
  );
};

export const Button2 = ({ children, style, onClick, ...rest }) => {
  return (
    <button
    id="btn2"
      style={{
        textDecoration: "none",
        fontFamily: "HelveticaBtn",
        height: "2.75rem",
        width: "11rem",
        display: "block",
        margin: "0 auto",
        backgroundColor: "#f4e3c9",
        backgroundImage: "url(webp/btn01_1.webp)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "400% 200%",
        backgroundPosition: '200% 0%',
        transition: "all 1s",
        fontWeight: "500", //Check after applying new fontFamily
        border: "black 2px solid",
        color: "black",
        position: "relative",
        fontSize: "0.75rem",
        fontWeight: "bold",
        paddingTop: "0.1rem",
        zIndex: "2",
        ...style,
      }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};
