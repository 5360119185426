import * as React from "react";
import { createContext, useContext, useState, useRef } from "react";
import {
  getAuth,
  signInAnonymously,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { Toaster } from "react-hot-toast";
import { httpsCallable } from "firebase/functions";
import { getGeo } from "./help";
import {
  doc,
  getDoc,
  setDoc,
  onSnapshot,
  increment,
  updateDoc,
  collection as firebaseColleciton,
} from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";
import { getHtml, rewardcollection } from "./core";
import { jwtDecode } from "jwt-decode";
import Popup from "./sso/component/popup";
import {
  firebaseApp,
  functions,
  sendWhatsapp,
  sendEmailMgs,
  db,
} from "./firebase";
import { SSO } from "./sso";
import { useNavigate } from "react-router-dom";

const SuperfanContext = createContext();

export function SuperfanProvider({ ...props }) {
  const { firebaseConfig, collection } = props;
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const [user, setUser] = useState(null);
  const [userMetaData, setUserMetaData] = useState(null);
  const [rewardToken, setRewardToken] = useState(null);
  const [oneTimeToken, setOneTimeToken] = useState(null);

  // trigger sign in progress
  const [afterSignInTo, setAfterSignInTo] = useState(null);
  const [canPlaySuperFan, setCanPlaySuperFan] = useState(false);
  const [selectedId, setSelectedId] = useState("pre-mobile");
  const nav = useNavigate();

  const startSignIn = ({ to, callBack = () => {} }) => {
    if (user?.isAnonymous === false) {
      throw new Error("User already signed in");
    }
    if (!to) {
      throw new Error("to is required");
    }

    setAfterSignInTo({
      to: to,
      callBack: callBack,
    });
    setCanPlaySuperFan(true);
  };

  const signIn = async () => {
    let type = localStorage.getItem("emailOrMobile").includes("@")
      ? "email"
      : "phone";
    const data = {
      type: type,
      measure: localStorage.getItem("emailOrMobile"),
      anonymousID: user.uid,
      collection: collection,
      brand: collection.split("-")[0],
    };

    const _signIn = httpsCallable(functions, "signin");
    const response = await _signIn(data);

    let token = response.data?.token;
    if (token) {
      signInWithCustomToken(auth, token);
    }
  };

  const twillioOTP = async () => {
    let type = localStorage.getItem("emailOrMobile").includes("@")
      ? "email"
      : "phone";
    return new Promise(async (resolve, reject) => {
      try {
        switch (type) {
          case "phone":
            try {
              let verifywhatsapp = httpsCallable(functions, "verifywhatsapp");
              let res1 = await verifywhatsapp({
                type: type,
                phone: localStorage.getItem("emailOrMobile"),
              });
              resolve(res1);
            } catch (e) {
              reject("Invalid Phone Number");
            }
            break;
          case "email":
            try {
              let verifyEmail = httpsCallable(functions, "verifyemail");
              let res = await verifyEmail({
                type: type,
                email: localStorage.getItem("emailOrMobile"),
              });
              resolve(res);
            } catch (e) {
              reject("Invalid Email");
            }
            break;
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  const checkOTP = async ({ measure, code }) => {
    let checkOtppp = httpsCallable(functions, "checkotp");
    let res = await checkOtppp({ measure: measure, code: code });
    return res;
  };

  const addInfo = async (info) => {
    let brand = collection.split("-")[0];
    let collectionName = "users-" + brand;
    if (!user) return;
    await setDoc(doc(db, collectionName, user?.uid), info, {
      merge: true,
    });
  };

  const googleSignIn = async (nav) => {
    try {
      let res = await signInWithPopup(auth, provider);
      let uid = res.user.uid;
      let userCollection = "users-" + collection.split("-")[0];
      if (uid) {
        enterCount(uid);
        let userRef = doc(db, userCollection, uid);
        setDoc(
          userRef,
          {
            email: res.user.email,
            campaigns: {
              [collection]: true,
            },
          },
          { merge: true }
        );
      }
    } catch (e) {
      return "error";
    }
  };

  async function recordEvent(key) {
    if (!key) {
      throw new Error("Key is required");
    }
    let _key = key.replace(/ /g, "_").toLowerCase();
    let userDocRef = doc(db, collection, user.uid);
    try {
      await updateDoc(userDocRef, {
        [`events.${key}.time`]: new Date().toJSON(),
        [`events.${key}.count`]: increment(1),
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  const twillioOTPSMS_EMAIL = async () => {
    let type = localStorage.getItem("emailOrMobile").includes("@")
      ? "email"
      : "phone";
    return new Promise(async (resolve, reject) => {
      try {
        switch (type) {
          case "phone":
            try {
              let verifywhatsapp = httpsCallable(functions, "verifysms");
              let res1 = await verifywhatsapp({
                type: type,
                phone: localStorage.getItem("emailOrMobile"),
              });
              resolve(res1);
            } catch (e) {
              reject("Invalid Phone Number");
            }
            break;
          case "email":
            try {
              let verifyEmail = httpsCallable(functions, "verifyemail");
              let res = await verifyEmail({
                type: type,
                email: localStorage.getItem("emailOrMobile"),
              });
              resolve(res);
            } catch (e) {
              reject("Invalid Email");
            }
            break;
        }
      } catch (e) {
        reject(e);
      }
    });
  };

  const signInWithUid = async ({ token }) => {
    let docRef = doc(db, rewardcollection, token);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      let { uid } = snapshot.data();
      let _getCustomToken = httpsCallable(functions, "getCustomToken");
      let res = await _getCustomToken({ uid: uid });
      let token = res.data;
      signInWithCustomToken(auth, token);
    } else {
      alert("Invalid Token");
      window.location.href = "/";
    }
  };

  const init = async () => {
    const _docRef = doc(db, collection, user.uid);
    const snapshot = await getDoc(_docRef);

    return new Promise(async (resolve, reject) => {
      if (snapshot.exists()) {
        setDoc(
          _docRef,
          {
            replay: increment(1),
          },
          { merge: true }
        );

        // setInterval(()=>{
        //   setDoc(_docRef, {
        //     timespent: increment(5)
        //   },{merge:true});
        // },5000);
      } else {
        const [brand, region, campaign] = collection.split("-");
        let geoData = await getGeo();
        const details = {
          brand,
          region,
          campaign,
          user: user?.uid,
          createTime: new Date().toJSON(),
          redeemTime: null,
          updateTime: null, // for bigquery
          userAgent: navigator.userAgent,
          replay: 0,
          timespent: 0,
          score: 0,
          events: {},
          dataCollection: null,
        };
        setDoc(
          _docRef,
          {
            ...details,
            ...geoData,
          },
          { merge: true }
        );

        // setInterval(()=>{
        //   setDoc(_docRef, {
        //     timespent: increment(5)
        //   },{merge:true});
        // },5000);
      }
    });
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setUserMetaData(null);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const generateToken = async () => {
    let _generateCustomToken = httpsCallable(functions, "generatecustomtoken");
    return await _generateCustomToken({
      collection: collection,
    })
      .then((res) => {
        let _ = jwtDecode(res.data.token);
        let { token, uid } = _;
        setOneTimeToken(token);
      })
      .catch((e) => {});
  };
  //Benny added
  const updatescore = async ({ score, name }) => {
    let _updatescore = httpsCallable(functions, "updatescore");
    await _updatescore({
      score: score,
      token: oneTimeToken,
      collection: collection,
      name: name,
      uid: user?.uid,
    });
  };

  const getLeaderboard = async () => {
    let _getLeaderboard = httpsCallable(functions, "getLeaderboard");
    let res = await _getLeaderboard({ collection: collection });
    return res.data;
  };

  async function enterCount(uid) {
    let totalRef = doc(db, collection, "total");
    let userRef = doc(db, collection, uid);
    try {
      let userDoc = await getDoc(userRef);
      let userData = userDoc.data();
      let enterId = userData ? userData.enterId : null;

      if (!enterId) {
        const data = await getDoc(totalRef);
        let _currentCount = 0;
        if (data.exists()) {
          let { currentCount } = data.data();
          _currentCount = currentCount || 0;
        }

        _currentCount++;

        await updateDoc(totalRef, {
          currentCount: _currentCount,
        });
      }

      await setDoc(
        userRef,
        {
          enterId: increment(1),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        generateToken();
      } else {
        signInAnonymously(auth, (_user) => {
          setUser(_user);
        });
      }
    });

    window.logout = () => {
      logout();
    };
  }, []);

  React.useEffect(() => {
    window.signIn = () => {
      signIn();
    };
    if (user) {
      init();
      let docRef = doc(db, collection, user?.uid);
      onSnapshot(docRef, (document) => {
        if (document.exists()) {
          let totalRef = doc(db, collection, "total");
          // get totalref data

          getDoc(totalRef)
            .then((totalDoc) => {
              let userData = document.data();
              let totalData = totalDoc.data();
              let { until } = totalData;
              let { enterId, rsvp } = userData;

              if (until && enterId && until >= enterId && rsvp === undefined) {
                userData["validClaim"] = true;
              } else {
                userData["validClaim"] = false;
              }

              setUserMetaData(userData);
            })
            .catch((e) => {
              let totalDocRef = doc(db, collection, "total");
              setDoc(totalDocRef, {
                until: 5,
              });
            });
        }
      });
    }

    if(user?.isAnonymous === false){
      setSelectedId("collect-info");
    }
  }, [user]);

  React.useEffect(() => {
    // check seach params
    let searchParams = new URLSearchParams(window.location.search);

    let token = searchParams.get("token");
    let shortentoken = searchParams.get("shortentoken");
    if(shortentoken){
      getDoc(doc(db, "shortenToken", shortentoken)).then((_doc) => {
        let { token } = _doc.data();
        signInWithCustomToken(auth, token).then(async (credential) => {
          if (credential?.user) {
            setUser(credential?.user);
            let uid = credential?.user?.uid;
            if (uid) {
              let fullUrl = localStorage.getItem("fullUrl");
              setAfterSignInTo(fullUrl);
  
              let userCollection = "users-" + collection.split("-")[0];
              let userRef = doc(db, userCollection, uid);

             getDoc(userRef).then((doc) => {
                let data = doc.data();
                if(!data?.phoneNumberIsverified && !data?.phoneNumber){
                  setDoc(
                    userRef,
                    {
                      phoneNumberIsverified: credential?.user?.phoneNumber,
                      phoneNumber: credential?.user?.phoneNumber,
                      campaigns: {
                        [collection]: true,
                      },
                    },
                    { merge: true }
                  )    
                }
             });
            }
          }
        });  
      });
    }
    else if (token && user?.isAnonymous) {
      signInWithCustomToken(auth, token).then(async (credential) => {
        if (credential?.user) {
          setUser(credential?.user);
          let uid = credential?.user?.uid;
          if (uid) {
            let fullUrl = localStorage.getItem("fullUrl");
            setAfterSignInTo(fullUrl);

            let userCollection = "users-" + collection.split("-")[0];
            let userRef = doc(db, userCollection, uid);
            getDoc(userRef).then((doc) => {
              let data = doc.data();
              if(!data?.phoneNumberIsverified && !data?.phoneNumber){
                setDoc(
                  userRef,
                  {
                    phoneNumberIsverified: credential?.user?.phoneNumber,
                    phoneNumber: credential?.user?.phoneNumber,
                    campaigns: {
                      [collection]: true,
                    },
                  },
                  { merge: true }
                )    
              }
           });
          }
        }
      });
    }
  }, [user]);

  return (
    <SuperfanContext.Provider
      value={{
        signIn,
        twillioOTP,
        twillioOTPSMS_EMAIL,
        addInfo,
        googleSignIn,
        checkOTP,
        user,
        db,
        collection,
        userMetaData,
        signInWithUid,
        recordEvent,
        functions,
        rewardToken,
        setRewardToken,
        updatescore,
        getLeaderboard,
        startSignIn,
        setUser,
        setSelectedId
      }}
    >
      {props.children}
      <Toaster />
      {rewardToken && <Popup />}
      <SSO
        setSelectedId={setSelectedId}
        selectedId={selectedId}
        afterSignInTo={afterSignInTo}
        setAfterSignInTo={setAfterSignInTo}
        setCanPlaySuperFan={setCanPlaySuperFan}
        canPlaySuperFan={canPlaySuperFan}
      />
    </SuperfanContext.Provider>
  );
}

export const useSuperfan = () => useContext(SuperfanContext);
