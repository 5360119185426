// App.js
import "./App.css";
import {
  Page1,
  Page2,
  Page3,
  Page4,
  V1page2,
  V1page3,
  V1page4,
  Page8,
  V1page1,
  Page10,
  Page11,
  Page12,
  Desktopblock,
} from "./pages";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import DeviceChecker from "./pages/components/deviceChecker/deviceChecker";
import UnityComponent from "./pages/components/UnityComponent";
import { useEffect, useState } from "react";

function App() {
  let isLaunched = false;
  return <>{isLaunched ? <V2 /> : <V1 />}</>;
}

const V2 = () => {
  const [playType, setPlayType] = useState(null);
  const location = useLocation(); // Get the current location

  return (
    <>
      <DeviceChecker />
      <Routes location={location}>
        <Route path="/*" element={<Page1 />} />
        <Route path="/songSelection" element={<Page2 setPlayType={setPlayType} />} />
        <Route path="/game" element={<UnityComponent playType={playType} />} />
        <Route path="/score" element={<Page3 />} />
        <Route path="/leaderboard" element={<Page4 />} />
        <Route path="/desktopblock" element={<Desktopblock />} />
      </Routes>
    </>
  );
};

const V1 = () => {
  const [playType, setPlayType] = useState(null);
  const location = useLocation(); // Get the current location

  return (
    <>
      <DeviceChecker />
      <Routes location={location}>
        <Route path="/" element={<V1page1 />} />
        <Route path="/rsvp" element={<V1page2 />} />
        <Route path="/pass" element={<V1page3 />} />
        <Route path="/gameLanding" element={<Page1 />} />
        <Route path="/songSelection" element={<Page2 setPlayType={setPlayType} />} />
        <Route path="/game" element={<UnityComponent playType={playType} />} />
        <Route path="/score" element={<Page3 />} />
        <Route path="/leaderboard" element={<Page4 />} />
        <Route path="/redemption" element={<Page10 />} />
        <Route path="/desktopblock" element={<Desktopblock />} />
        <Route path="/date" element={<V1page4 />} />
      </Routes>
    </>
  );
};

export default App;
