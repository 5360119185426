import { Button, Wraper } from "./components";
import Footer from "./components/Footer";
import { useNavigate } from "react-router-dom";
import StampAnimation from "./components/StampAnimation";
import { useEffect, useState } from "react";
import { useSuperfan } from "../superfancontext";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
  db,
  functions,
  sendWhatsapp,
  sendEmailMgs,
} from "../superfancontext/firebase";
import { getHtml } from "../superfancontext/core";
import Lottie from "react-lottie";
import LoadingAnimation from "./components/lotties/loading.json";
import { set } from "animejs";

const Index = () => {
  const { userMetaData, user, acceptRSVP, startSignIn, setUser, collection } =
    useSuperfan();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const loadingOption = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Wraper>
      {isLoading ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "80%",
          }}
        >
          {/* <p>Loading leaderboard...</p> */}
          <Lottie options={loadingOption} height={100} width={100} />
        </div>
      ) : (
        <div
          style={{
            height: "100svh",
          }}
        >
          <div className="block" style={{ height: "5svh" }} />
          <div className="imgContainer" style={{ width: "35%" }}>
            <img
              style={{
                position: "relative",
                width: "100%",
                zIndex: "2",
              }}
              src="/webp/logo.webp"
              alt="coachLogo"
            />
          </div>
          <div className="block" style={{ height: "2svh" }} />
          <div
            className="imgContainer"
            style={{ height: "35%", width: "fit-content" }}
          >
            <img
              src="/gif/Stamp_Turn_3.gif"
              alt="stamp"
              style={{
                height: "100%",
              }}
            />
          </div>
          <div className="block" style={{ height: "4svh" }} />
          <div
            style={{
              margin: "0 auto",
            }}
          >
            <h1
              style={{
                fontSize: "2.4rem",
                lineHeight: "2.2rem",
              }}
            >
              MAKE AN IMPRESSION
            </h1>
            <div className="block" style={{ height: "2svh" }} />
            <div
              style={{
                margin: "0 auto",
                width: "90%",
              }}
            >
              <p
                style={{
                  fontSize: "1.95svh",
                  lineHeight: "1.15rem",
                }}
              >
                You're invited to step into an immersive space where leather
                craftsmanship meets printing artistry and enjoy exclusive
                in-store perks.
                <div className="block" style={{ height: "2svh" }} />
                <span style={{ fontWeight: "bold" }}>
                  Open to public 8-10 November.
                </span>
                <div className="block" style={{ height: "1svh" }} />
                <p
                  style={{
                    fontSize: "1.95svh",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}> Coach Play APW </span>
                  <br />
                  C-01, 29, Jalan Riong <br /> 59100, Kuala Lumpur, Malaysia
                </p>
              </p>
            </div>
            <div className="block" style={{ height: "4svh" }} />
            <Button
              style={{
                margin: "auto",
              }}
              onClick={async () => {
                setIsLoading(true);
                localStorage.setItem(
                  "fullUrl",
                  window.location.origin + "/date"
                );

                if (user?.isAnonymous) {
                  startSignIn({
                    to: "/date",
                    callBack: async () => {
                      let user = await new Promise((resolve) => {
                        setUser((prev) => {
                          resolve(prev);
                          return prev;
                        });
                      });

                      if (user) {
                        let docRef = doc(db, collection, user.uid);
                        let docSnap = await getDoc(docRef);

                        if (docSnap.exists()) {
                          let data = docSnap.data();
                          let { rsvp } = data; // Get the RSVP status

                          // Navigate based on RSVP status
                          if (rsvp === true || rsvp === false) {
                            console.log("User has RSVP'd11");
                            navigate("/pass"); // User has RSVP'd
                          } else {
                            console.log("User has not RSVP'd11");
                            navigate("/date"); // User has not RSVP'd
                          }
                        }
                      }
                    },
                  });
                } else {
                  // If user is logged in (not anonymous)
                  console.log("User is logged in");
                  let docRef = doc(db, collection, user.uid);
                  let docSnap = await getDoc(docRef);

                  if (docSnap.exists()) {
                    let data = docSnap.data();
                    let { rsvp } = data; // Get the RSVP status

                    // Navigate based on RSVP status
                    if (rsvp === true || rsvp === false) {
                      console.log("User has RSVP'd");
                      navigate("/pass"); // User has RSVP'd
                    } else {
                      console.log("User has not RSVP'd");
                      navigate("/date"); // User has not RSVP'd
                    }
                  }
                }
              }}
            >
              RSVP NOW
            </Button>
          </div>

          <img
            src="/webp/tile.webp"
            alt="coachLogo"
            style={{
              position: "absolute",
              width: "35svw",
              right: "15%",
              bottom: "5%",
              transform: "translate(50%, 50%)",
              zIndex: 1,
            }}
          />
        </div>
      )}
    </Wraper>
  );
};

export default Index;
