import React from "react";
import { Wraper } from "./components";
import RewardPage from "./components/RewardPage";
import { useSuperfan } from "../superfancontext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
//import RewardPage from "./components/TestReward.js";

const Index = () => {
  const { user, startSignIn } = useSuperfan();
  const navigate = useNavigate();

  return (
    <Wraper>
      <div
        style={{
          opacity: user?.isAnonymous ? 0 : 1,
        }}
      >
        <RewardPage />
      </div>
    </Wraper>
  );
};

export default Index;
