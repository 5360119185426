import { useEffect, useState } from "react";
import { Button, Wraper } from "./components";
import { useNavigate, useLocation } from "react-router-dom";

import { useSuperfan } from "../superfancontext";

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();

  //const score = location.state?.score || localStorage.getItem("tempReceivedScore") || 0;
  const score = localStorage.getItem("tempReceivedScore") || 0;

  useEffect(() => {
    // Store the score in localStorage whenever it changes
    if (location.state?.score) {
      localStorage.setItem("score", location.state.score);
    }
  }, [location.state?.score]);

  return (
    <Wraper style={{ position: "relative" }}>
      <div className="block" style={{ height: "5svh" }} />
      <div
        style={{
          width: "90%",
          margin: "0 auto",
        }}
      >
        <div style={{ width: "50%" }}>
          <img
            src="/webp/logo.webp"
            alt="coachLogo"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <div className="block" style={{ height: "1svh" }} />
        <h1 style={{ textAlign: "left" }}>ONE FOR THE BOOKS</h1>
        <div className="block" style={{ height: "1svh" }} />
        <p style={{ textAlign: "left" }}>
          Great job! Check your score now or play again to beat your record.
        </p>
      </div>
      <div className="block" style={{ height: "43svh" }} />

      <div
        className="imgContainer"
        style={{
          height: "45svh",
          //maxHeight: "28vh",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-55%, -60%) rotate(1deg) scale(0.9)",
        }}
      >
        <img
          src="/webp/score.webp"
          alt="coachLogo"
          style={{
            position: "relative",
            zIndex: "8",
          }}
        />
      </div>
      <div
        className="imgContainer"
        style={{
          height: "20svh",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(10%, -5%)",
        }}
      >
        <div className="score">{score}</div>
      </div>
      <div className="block" style={{ height: "3svh" }} />
      <div className="specialBlock"/>
      <Button
        onClick={() => {
          navigate("/leaderboard");
        }}
      >
        LEADERBOARD
      </Button>
      <div className="block" style={{ height: "1svh" }} />
      <Button
        onClick={() => {
          window.location.href = window.location.origin + "/songSelection";
        }}
      >
        PLAY AGAIN
      </Button>
      <div className="block" style={{ height: "1svh" }} />
      <Button
        onClick={() => {
          window.location.href = window.location.origin + "/rsvp";
        }}
      >
       EVENT SIGN UP
      </Button>
      <img
        src="/webp/tile.webp"
        alt="coachLogo"
        style={{
          position: "absolute",
          width: "35svw",
          right: "15%",
          bottom: "5%",
          transform: "translate(50%, 50%)",
          zIndex: 1,
        }}
      />

      <div className="block" style={{ height: "2svh" }} />

      <span
        onClick={() => {}}
        style={{
          display: "block",
          fontSize: "0.6em",
          fontWeight: "bold",
          textAlign: "center",
          textDecoration: "underline",
        }}
      >
        T&C apply
      </span>

      {/* {userMetaData?.rsvp != null ? null : <PopUp />} */}
      {/* {popup && (
        <PopUp
          onClose={() => {
            setPopup(false);
          }}
        />
      )} */}
    </Wraper>
  );
};

export default Index;
