import axios from "axios";

export async function getGeo(){
    try{
        const { ip } = await axios
        .get("https://api.ipify.org/?format=json")
        .then((res) => res.data);
    
        const { data } = await axios.get(`https://ipapi.co/${ip}/json/`);
    
        const {
            city,
            country,
            latitude,
            longitude,
            timezone,
            utc_offset,
        } = data;
    
        const geoData = {
            city,
            country,
            latitude,
            longitude,
            timezone,
            utc_offset,
        };
    
        return {
            geoData
        }    
    }
    catch{
        return {
            geoData : {}
        }
    }
}

export function generate5degitToken(){
    let list = "abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    let token = "";
    for(let i = 0; i < 5; i++){
        token += list[Math.floor(Math.random() * list.length)]
    }
    return token;
}