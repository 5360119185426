import { OtpBox} from '../common';
import { useState , useRef, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from "axios";
import { useSuperfan } from '../../index';
import Lottie from 'react-lottie';
import loadingJson from "./loading.json";
import { validateOTP } from "../../firebase";

const Index = (props) => { 
  const { setSelectedId , style , selectedId , setCanPlaySuperFan ,  ...rest } = props
  const [input , setInput] = useState(Array(6).fill(null));
  const [isValid, setValid] = useState(false);
  const inputRefs = useRef([]);
  const {twillioOTP , twillioOTPSMS_EMAIL , signIn } = useSuperfan();
  const nav = useNavigate();

  const focusNextInput = (index) => {
    if (index + 1 < inputRefs.current.length) {
      inputRefs.current[index + 1].focus();
    }
  };

  const focusPrevInput = (index) => {
    index < 0 ? index = 0 : index = index;
    inputRefs.current[index].focus();
  }

  const regexOtp = /^\d{6}$/;
  const [resendMasaage , setResendMessage] = useState("Resend code?");
  const [resendMasaage2 , setResendMessage2] = useState("Resend via sms?");

  const isMobile = localStorage.getItem("emailOrMobile")?.includes("@") ? false : true;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingJson,
  }

  useMemo(async() => {
    let otp = input.join('');

    if (regexOtp.test(otp) && localStorage.getItem("emailOrMobile")) {
      let lottieContainer = document.getElementById('lottieContainer');
      lottieContainer.style.opacity = 1;
      try{
         let ress = await axios.post("https://api.superfan.digital/check" , {
          measure : localStorage.getItem("emailOrMobile"),
          code : otp
         });

         if(ress.status === 200){
          signIn();
        }
      }
      catch(e){
        let lottieContainer = document.getElementById('lottieContainer');
        lottieContainer.style.opacity = 0;
        setValid(true);
      }
    } else {
      setValid(false);
    }
  }, [input]);

  useEffect(() => {
    if(localStorage.getItem("emailOrMobile") === null){
      nav("/pre-mobile");
    }
  }, []);
   
  return (
    <motion.div
      style = {{
        backgroundColor: "transparent",
        textAlign: "left",
        height: "100%",
        width: "100%",
        padding: "0 2.5rem",
        ...style,
      }}

      initial = {{
        opacity : 0,
      }}

      animate = {{
        opacity : 1,
      }}

      transition={{
        delay : 0.5,
        duration: 0.8,
      }}
    >
      <Back setSelectedId = {setSelectedId}/>
      <div className='block' style={{height : "5.5rem"}}/>
      <h2
        style={{color : "white" , fontFamily : "Poppins-Bold" , textAlign : "left"}}
      >
        Enter Code
      </h2>
      <div className='block' style={{height : "1rem"}}/>
      <h5
        style={{color : "white"}}
      >
        Please enter 6 digit-code sent via<br/>
        email or whatsapp.
      </h5>
      <div className='block' style={{height : "2rem"}}/>
      <div 
        style={{
          height:"fit-content",
          width: "100%",
          border: "none",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }} 
        className='otpBlock'
      >
        {
          input.map((item, index) => (
              <OtpBox 
                key={index} 
                index={index} 
                value={item}
                onChange={(e)=>{
                  e.preventDefault();
                  if(e.target.value.length === 6){
                    setInput((prev) => {
                      let temp = [...prev];
                      for(let i = 0; i < 6; i++){
                        temp[i] = e.target.value[i];
                      }
                      e.target.value = temp[index];
                      return temp;
                    });
                  }
                  setInput((prev) => {
                    let temp = [...prev];
                    temp[index] = e.target.value.at(0);
                    focusNextInput(index);
                    return temp;
                  });
                }}

                onKeyDown={(e)=>{
                  if(e.key === "Backspace"){
                    e.preventDefault();
                    setInput((prev) => {
                      let temp = [...prev];
                      temp[index] = null;
                      return temp;
                    });
                    e.target.value = '';
                    focusPrevInput(index - 1 );
                  }
                }}
                
                ref={(el) => inputRefs.current[index] = el}/>
            ))
        }
      </div>
      <div className='block' style={{height : "1.5svh"}}/>

      <h6 
        style={{
          textAlign : "center",
          color : "#00BF63",
          display : isValid ? "block" : "none"
        }}
      >
        Invalid OTP, please try again...
      </h6>
      <div className='block' style={{height : "2svh"}}/>

      <div
        style={{height : "fit-content", display : "flex", justifyContent : "space-between" , alignItems : "center"}}
      >
        <CopyAndPaste
          onClick={()=>{
            try{
              navigator.clipboard.readText().then((clipText)=>{
                if(clipText.split('').length === 6){
                  setInput(clipText.split(''));

                  new Array(6).fill(null).forEach((item, index) => {
                    let _ = document.querySelector(`.otp${index}`);
                    _.value = clipText.split('')[index];
                  });
                }
              })
            }
            catch(e){
            }
          }}
        />

        <div>
          <h5
            style={{color : "rgb(154 154 154)" , textAlign : "right"}}
            onClick={async()=>{
              setResendMessage("Resending code...");
              twillioOTP().then(()=>{
                setResendMessage("Code sent!");
                setTimeout(()=>{
                  setResendMessage("Resend code?");

                  let resendSMS = document.getElementById('resendSMS');
                  if(isMobile){
                    resendSMS.style.pointerEvents = "auto";
                    resendSMS.style.opacity = 1;
                  }
                }, 1500);
              })
            }}
          >
            {resendMasaage}
          </h5>
        </div>
      </div>

      <div
        style={{
          width : "15rem",
          position : "absolute",
          left : "50%",
          transform : "translate(-50% , 50%)",
          bottom : "15%",
          opacity : 0,
        }}
        id='lottieContainer'
      >
        <Lottie
          options={defaultOptions}
        />
      </div>

      {/* <h5
        style={{
            color : "rgb(71 76 80)" , 
            textAlign : "center" , 
            position : "absolute" , 
            bottom : "2rem" , 
            left : "50%" , 
            transform : "translateX(-50%)" , 
            textDecoration : "underline",
            pointerEvents : isMobile ? "auto" : "none",
            opacity : 0,
        }}
        id='resendSMS'
        onClick={async()=>{
          setResendMessage2("Resending code...");
          twillioOTPSMS_EMAIL().then(()=>{
            setResendMessage2("Code sent!");

            setTimeout(()=>{
              setResendMessage2("Resend via sms?");
            }, 1500);
          })
        }}
        >
          {resendMasaage2}
      </h5> */}
     </motion.div>
    );
}

export const CopyAndPaste = (props)=> { 
  return (
      <svg xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          style={{height : "1.2rem"}}
          {
            ...props
          }
      >
          <path d="M0 0h48v48H0z"/>
          <path fill="white" d="M32 2H8C5.79 2 4 3.79 4 6v28h4V6h24V2zm6 8H16c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 32H16V14h22v28z" />
      </svg>
  )
}

const Back = ({ setSelectedId })=>{
  return (
    <div
      style={{
        height : "5svh",
        maxHeight : "2rem",
        width : "fit-content",
        position : "absolute",
        left : "1rem",
        top : "1rem",
      }}

      onClick={()=>{
        let currentId = localStorage.getItem("currentId") ?? "pre-email";
        setSelectedId(currentId);
      }}
    > 
       <svg style = {{height : "100%"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="11" stroke="white" strokeWidth={1.2}/>
        <path fill = "white" d="M19 11H7.14l3.63-4.36a1 1 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13A1 1 0 0 0 4 12a1 1 0 0 0 .07.36c0 .05 0 .08.07.13a1.19 1.19 0 0 0 .09.15l5 6A1 1 0 0 0 10 19a1 1 0 0 0 .64-.23 1 1 0 0 0 .13-1.41L7.14 13H19a1 1 0 0 0 0-2z" />
      </svg>
    </div>
  )
}

export default Index;

