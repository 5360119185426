import React, { useMemo, useState, useEffect } from "react";
import { Button } from "..";
import { Navigate, useNavigate } from "react-router-dom";
import { useSuperfan } from "../../../superfancontext";
import { generate5degitToken } from "../../../superfancontext/help";
import { db } from "../../../superfancontext/firebase";
import { httpsCallable } from "firebase/functions";
import { rsvp, noneRsvp } from "./rewards";
import { setDoc, doc } from "firebase/firestore";
import { radialGradient } from "framer-motion/client";
import Lottie from "react-lottie";
import LoadingAnimation from "../lotties/loading.json";

const styles = {
  container: {
    width: "100svw",
    maxWidth: "50rem",
    height: "100svh",
    margin: "0 auto",
    padding: "0.7rem",
    backgroundColor: "#f4e3c9",

    //borderRadius: '10px',
    //boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  scrollableContainer: {
    maxHeight: "50svh",
    overflowY: "auto",
    overflowX: "hidden",
    border: "0.1rem solid black",
    //border: 'none',
    padding: "0.3px",
    margin: "0 auto",
    marginleft: "auto",
    borderRadius: "0.625px",
    backgroundColor: "#f4e3c9",
    alignItems: "center",
    //boxShadow: "1rem 1rem 1rem 1rem rgba(0, 0, 0, 0.2)",
  },

  rewardItem: {
    display: "flex",
    marginTop: "0.7rem",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1.3rem",
    position: "relative",
    margin: "auto",
    borderRadius: "0",
    backgroundImage: "url(webp/box.webp)",
    //backgroundSize: 'cover',
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "20rem 6rem",
    //boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    overflow: "hidden",
    height: "6.5rem",
    width: "20rem",
    gap: "11%",
    fontSize: "0.9rem",
    zIndex: "-1",
  },
  rewardImage: {
    width: "4.6rem",
    height: "4.6rem",
    marginRight: "0rem",
    marginLeft: "0.25rem",
  },
  button: {
    padding: "0.3rem 2rem",
    backgroundColor: "black",
    color: "white",
    border: 'none',
    width: "80%",
    cursor: "pointer",
    fontWeight: "bold",
  },
};

const Header = ({ namePull }) => {
  const { functions, user, collection, userMetaData } = useSuperfan();
  const [name, setName] = useState(namePull);

  return (
    <div
      style={{
        textAlign: "left",
        //marginBottom: "2rem",
        position: "relative",
        zIndex: 1,
      }}
    >
      <div
        className="imgContainer"
        style={{
          height: "2.5svh",
          marginLeft: "0.5rem",
        }}
      >
        <img src="/webp/logo.webp" alt="coachLogo" />
      </div>
      <div className="block" style={{ height: "2svh" }} />
      <h1
        style={{
          textAlign: "left",
          marginLeft: "0.5rem",
          lineHeight: "2.3rem",
        }}
      >
        WELCOME,
        <br />
        {namePull?.toUpperCase()}
      </h1>
      <div className="block" style={{ height: "2svh" }} />
      {userMetaData?.rsvp === true && (
        <p
          style={{
            textAlign: "left",
            marginLeft: "0.5rem",
          }}
        >
          Find your ticket below and redeem exclusive perks throughout the
          space. Enjoy!
        </p>
      )}
      {userMetaData?.rsvp === false && (
        <p
          style={{
            textAlign: "left",
            marginLeft: "0.5rem",
          }}
        >
          Find your ticket below and redeem the perks throughout the space.
          Enjoy!
        </p>
      )}
    </div>
  );
};

const RewardItem = ({ title, buttonText, imgSrc, blurfilter }) => {
  const { setRewardToken, userMetaData, user, collection } = useSuperfan();
  const [disable, setDisable] = useState(false);
  const [blur] = useState(blurfilter);

  const onClickhander = async () => {
    let json = {
      uid: user.uid,
      reward: title,
      collection: collection,
      imgSrc: window.location.origin + imgSrc,
    };

    let token =
      user.uid.slice(0, 3) + title.slice(0, 3) + collection.slice(0, 3);
    token = token.replace(" ", "");
    let docRef = doc(db, "qrcode", token);
    setRewardToken(token);
    setDoc(docRef, json);
  };

  useMemo(() => {
    if (userMetaData) {
      if (userMetaData?.rewards && userMetaData?.rewards[title]?.redeemed) {
        setRewardToken(null);
        setDisable(true);
      }
    }
  }, [userMetaData]);

  return (
    <div>
      <section
        style={{
          height: "7.5rem",
          display: "flex",
          alignItems: "center",
          position: "relative",
          margin: "0.3rem auto",
          maxWidth: "21rem",
          minWidth: "16rem",
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "fit-content",
            zIndex: 0,
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img src="/webp/box.webp" style={{ height: "100%" }} />
        </div>
        {blur && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "white",
              fontSize: "1.5rem",
              fontWeight: "bold",
              textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)", // To make text stand out
              zIndex: 2,
            }}
          >
            Coming Soon
          </div>
        )}
        <section style={{ flex: 1, width: "auto", zIndex: 1 }}>
          <div
            style={{
              height: "6rem",
              width: "fit-content",
              margin: "auto",
              position: "relative", // Add relative positioning to place text on top
            }}
          >
            <img
              src={imgSrc}
              alt={title}
              style={{
                height: "100%",
                filter: blur
                  ? disable
                    ? "grayscale(100%) blur(3px)"
                    : "blur(3px)"
                  : disable
                  ? "grayscale(100%)"
                  : "none",
                pointerEvents: disable || blur ? "none" : "auto",
              }}
            />
          </div>
        </section>

        <div
          style={{
            textAlign: "center",
            flex: 1,
            zIndex: 1,
            transform: "translate(-10%, 0)",
          }}
        >
          <h3
            style={{
              padding: "0rem",
              filter: blur
                ? disable
                  ? "grayscale(100%) blur(3px)"
                  : "blur(3px)"
                : disable
                ? "grayscale(100%)"
                : "none",
              pointerEvents: disable || blur ? "none" : "auto",
            }}
          >
            {title}
          </h3>
          <div
            className="block"
            style={{
              height: "2svh",
            }}
          />

          <button
            style={{
              ...styles.button,
              filter: blur
                ? disable
                  ? "grayscale(100%) blur(3px)"
                  : "blur(3px)"
                : disable
                ? "grayscale(100%)"
                : "none",
              pointerEvents: disable || blur ? "none" : "auto",
              background: disable ? 'grey' : 'black',
            }}
            onClick={onClickhander}
          >
            {disable ? 'Redeemed':buttonText}
          </button>
        </div>
      </section>
    </div>
  );
};

const ScrollableRewards = () => {
  const { userMetaData, user } = useSuperfan();

  let arr = useMemo(() => {
    if (userMetaData?.rsvp) {
      return rsvp;
    } else {
      return noneRsvp;
    }
  }, [userMetaData]);

  return (
    <div
      style={{
        height: "50svh",
        overflowY: "auto",
      }}
    >
      <div className="block" style={{ height: "2svh" }} />
      {arr.map((reward, index) => (
        <RewardItem
          title={reward.title}
          buttonText={reward.buttonText}
          imgSrc={reward.imgSrc}
          blurfilter={reward.disableBlur}
          key={reward.title + index}
        />
      ))}

      <div style={{ height: "1svh", width: "100%" }}></div>
    </div>
  );
};

const RewardPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { user, userMetaData, collection, functions } = useSuperfan();
  let getLeftInfo = httpsCallable(functions, "getleftinfo");
  const [name, setName] = useState(null);

  const loadingOption = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const run = async () => {
      await new Promise(async (resolve) => {
        try {
          let res = await getLeftInfo({
            uid: user.uid,
            collection: collection,
          });
          const { name } = res?.data;
          setName(name);
          resolve();
        } catch (e) {}
      });

      if (userMetaData?.rsvp === true || userMetaData?.rsvp === false) {
        setIsLoading(false); // Stop loading when userMetaData is available
      }
    };

    run();
  }, [userMetaData?.rsvp]);

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "80%",
          }}
        >
          {/* <p>Loading leaderboard...</p> */}
          <Lottie options={loadingOption} height={100} width={100} />
        </div>
      ) : (
        <div>
          <div className="block" style={{ height: "5svh" }} />
          <Header namePull={name} />
          <div className="block" style={{ height: "2svh" }} />
          <ScrollableRewards />

          <div style={{ height: "5svh", width: "100%" }}></div>
          <Button
            onClick={() => {
              navigate("/pass");
            }}
          >
            BACK
          </Button>

          <div
            style={{
              height: "14svh",
              position: "absolute",
              top: "3%",
              right: "7%",
              zIndex: "1",
            }}
            className="floating"
          >
            <img
              style={{
                height: "100%",
              }}
              src="webp/stamp.webp"
            ></img>
          </div>
        </div>
      )}
    </div>
  );
};

export default RewardPage;
