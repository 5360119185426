import { nav } from "framer-motion/client";
import { Button, Button2, Wraper } from "./components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { increment } from "firebase/firestore";
import { doc, getDoc, setDoc } from "firebase/firestore";
import {
  db,
  functions,
  sendWhatsapp,
  sendEmailMgs,
} from "../superfancontext/firebase";
import { useSuperfan } from "../superfancontext";
import { useEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { getHtml } from "../superfancontext/core";
import LoadingAnimation from "./components/lotties/loading.json";
import Lottie from "react-lottie";
import axios from "axios";
import { set } from "animejs";

const Index = () => {
  const { user, setSelectedId, userMetaData } = useSuperfan();
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  let collection = "coach-my-apw";
  const [isLoading, setIsLoading] = useState(true);

  const loadingOption = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const handleSSend = async ({
    isExclusive,
  }) => {
    const getLeftInfo = httpsCallable(functions, "getleftinfo");
    let res = await getLeftInfo({
      uid: user?.uid,
      collection: collection, // Pass the appropriate collection name
    });

    const { phoneNumber, email, phoneNumberIsverified, emailIsverified } = res?.data;

    let type = null;
    if (phoneNumberIsverified) {
      type = "phone";
    } else if (emailIsverified) {
      type = "email";
    }


    let _phone = phoneNumberIsverified || phoneNumber;
    let _email = emailIsverified || email;

    switch (type) {
      case "phone":
        let mobileToken = await axios.get("https://api.superfan.digital/getSignInToken" , {
          params: {
            measure : _phone,
          },
        });

        let url = window.location.origin + `/redemption?shortentoken=${mobileToken.data?.token}`;

        await handleSendWhatsAppAndEmail({
          url,
          selectedDate,
          phone: _phone,
          email: _email,
          isExclusive,
        });
        break;

      case "email":
        let emailToken = await axios.get("https://api.superfan.digital/getSignInToken" , {
          params: {
            measure : email,
          },
        });

        let url2 = window.location.origin + `/redemption?shortentoken=${emailToken.data?.token}`;
        await handleSendWhatsAppAndEmail({
          url: url2,
          selectedDate,
          phone: _phone,
          email: _email,
          isExclusive
        });
        break;
    }
  };

  const handleRSVP = async () => {
    const docRef = doc(db, collection, "rsvpCountDoc");
    let docSnap = await getDoc(docRef);
    const userDocRef = doc(db, collection, user.uid);
    let userDocSnap = await getDoc(userDocRef);

    let rsvp;
    let alreadyClaimed;
    let rsvpCount = 0;
    let rsvpLimit = 500;

    if (userDocSnap.exists()) {
      let data = userDocSnap.data();
      rsvp = data.rsvp;
      alreadyClaimed = data.alreadyClaimed;
    } else {
      console.log("User document does not exist.");
    }

    let dateField = "";
    if (selectedDate === "8 November 2024") {
      dateField = "rsvpDate1";
    } else if (selectedDate === "9 November 2024") {
      dateField = "rsvpDate2";
    } else if (selectedDate === "10 November 2024") {
      dateField = "rsvpDate3";
    }

    //If rsvpCountDoc does not exist, create it
    if (!docSnap.exists()) {
      await setDoc(docRef, {
        rsvpLimit: rsvpLimit,
        rsvpDate1: 0,
        rsvpDate2: 0,
        rsvpDate3: 0,
      });
    } else {
      const data = docSnap.data();
      rsvpCount = data[dateField] || 0;
      if (data.rsvpLimit === undefined) {
        // Add the default rsvpLimit to the document in the database
        await setDoc(docRef, { rsvpLimit: 500 }, { merge: true });
        console.log("rsvpLimit was not found. Setting default value (500).");
      } else {
        // If rsvpLimit exists, use it
        rsvpLimit = data.rsvpLimit;
      }
    }

    return { rsvp, alreadyClaimed, rsvpCount, rsvpLimit, dateField , docRef , userDocRef };
  }

  const handleConfirm = async () => {
    setIsLoading(true);
    let {rsvp, alreadyClaimed, rsvpCount, rsvpLimit, dateField , docRef , userDocRef } = await handleRSVP();

    // exclusive pass
    if (
      rsvpCount < rsvpLimit &&
      rsvp === undefined &&
      alreadyClaimed === undefined
    ){
      await setDoc(docRef, { [dateField]: increment(1) }, { merge: true });
      await setDoc(
        userDocRef,
        {
          rsvp: true,
          alreadyClaimed: true,
          selectedDate: selectedDate,
        },
        { merge: true }
      );

      handleSSend({
        isExclusive: true,
      });
    }

    else if(rsvpCount >= rsvpLimit &&
      rsvp === undefined &&
      alreadyClaimed === undefined
    ){
      await setDoc(
        userDocRef,
        {
          rsvp: false,
          alreadyClaimed: true,
          selectedDate: selectedDate,
        },
        { merge: true }
      );

      handleSSend({
        isExclusive: false,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (userMetaData?.rsvp === true || userMetaData?.rsvp === false) {
      navigate("/pass");
    } else {
      setIsLoading(false);
    }
  }, [userMetaData]);

  return (
    <>
      {isLoading ? (
        <Wraper>
          <div
            style={{
              textAlign: "center",
              marginTop: "80%",
            }}
          >
            {/* <p>Loading leaderboard...</p> */}
            <Lottie options={loadingOption} height={100} width={100} />
          </div>
        </Wraper>
      ) : (
        <Wraper>
          <div className="block" style={{ height: "5svh" }} />
          <div className="imgContainer" style={{ width: "22svh" }}>
            <img
              style={{
                position: "relative",
                width: "100%",
                zIndex: "2",
              }}
              src="/webp/logo.webp"
              alt="coachLogo"
            />
          </div>
          <div className="block" style={{ height: "2svh" }} />
          <div
            className="imgContainer"
            style={{ height: "35%", width: "fit-content" }}
          >
            <img
              src="/gif/Stamp_Turn_3.gif"
              alt="stamp"
              style={{
                height: "100%",
              }}
            />
          </div>
          <h1
            style={{
              fontSize: "5.2svh",
              lineHeight: "5svh",
            }}
          >
            PLAN YOUR DAY, <br /> MAKE YOUR WAY!
          </h1>
          <div>
            <div
              style={{
                fontSize: "1.2rem",
              }}
            >
              <div className="block" style={{ height: "3svh" }} />
              <Button2
                style={{
                  width: "35svh",
                  height: "6.5svh",
                  fontSize: "2.2svh",
                  border:
                    selectedDate === "8 November 2024"
                      ? "none"
                      : "black 2px solid",
                  backgroundColor:
                    selectedDate === "8 November 2024" ? "#F2A900" : "#f4e3c9",
                }}
                onClick={() => handleDateClick("8 November 2024")}
              >
                8 November 2024
              </Button2>
              <div className="block" style={{ height: "2svh" }} />
              <Button2
                style={{
                  width: "35svh",
                  height: "6.5svh",
                  fontSize: "2.2svh",
                  border:
                    selectedDate === "9 November 2024"
                      ? "none"
                      : "black 2px solid",
                  backgroundColor:
                    selectedDate === "9 November 2024" ? "#F2A900" : "#f4e3c9",
                }}
                onClick={() => handleDateClick("9 November 2024")}
              >
                9 November 2024
              </Button2>
              <div className="block" style={{ height: "2svh" }} />
              <Button2
                style={{
                  width: "35svh",
                  height: "6.5svh",
                  fontSize: "2.2svh",
                  border:
                    selectedDate === "10 November 2024"
                      ? "none"
                      : "black 2px solid",
                  backgroundColor:
                    selectedDate === "10 November 2024" ? "#F2A900" : "#f4e3c9",
                }}
                onClick={() => handleDateClick("10 November 2024")}
              >
                10 November 2024
              </Button2>
              <div className="block" style={{ height: "3svh" }} />
              <Button
                style={{
                  width: "25svh",
                  height: "6.5svh",
                  fontSize: "2svh",
                  opacity: selectedDate ? "1" : "0",
                  pointerEvents: selectedDate ? "auto" : "none",
                }}
                onClick={() => {
                  handleConfirm();
                }}
              >
                CONFIRM
              </Button>
            </div>
          </div>
          <img
            src="/webp/tile.webp"
            alt="coachLogo"
            style={{
              position: "absolute",
              width: "20svh",
              right: "15%",
              bottom: "5%",
              transform: "translate(50%, 50%)",
              zIndex: 1,
            }}
          />
        </Wraper>
      )}
    </>
  );
};


async function handleSendWhatsAppAndEmail({
  url , selectedDate , phone , email , isExclusive = false
}) {
  try{
    let contentSid = isExclusive ? "HXe0ad1043043c1951122c5d475e16f69a" : "HX2ad4e7b5affa3dee39de2eb19a0a5e47";

    await sendWhatsapp({
      contentSid: contentSid,
      contentvariable: {
        1: selectedDate,
        2: url,
      },
      phone: phone,
    });
  
    let html;
    
    switch (isExclusive){
      case true:
        if (selectedDate === "8 November 2024") {
          html = getHtml(url, "https://test.conten.tech/CoachAPW/Exclusive_8.jpg");
        } else if (selectedDate === "9 November 2024") {
          html = getHtml(url, "https://test.conten.tech/CoachAPW/Exclusive_9.jpg"); // Replace with the correct image URL for 9 Nov
        } else if (selectedDate === "10 November 2024") {
          html = getHtml(url, "https://test.conten.tech/CoachAPW/Exclusive_10.jpg"); // Replace with the correct image URL for 10 Nov
        }
        break;
      case false:
        if (selectedDate === "8 November 2024") {
          html = getHtml(url, "https://test.conten.tech/CoachAPW/General_8.jpg");
        } else if (selectedDate === "9 November 2024") {
          html = getHtml(url, "https://test.conten.tech/CoachAPW/General_9.jpg"); // Replace with the correct image URL for 9 Nov
        } else if (selectedDate === "10 November 2024") {
          html = getHtml(url, "https://test.conten.tech/CoachAPW/General_10.jpg"); // Replace with the correct image URL for 10 Nov
        }
        break;
    }
  
    await sendEmailMgs({
      to: email,
      subject: "Here's your pass to COACH PLAY APW: THE CRAFT EXPERIENCE",
      text: `Here's your pass to COACH PLAY APW: THE CRAFT EXPERIENCE`,
      html: html,
    });  
  }
  catch(e){
    console.log(e);
  }
}

export default Index;
