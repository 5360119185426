import { color, motion } from "framer-motion";
import QRCode from "react-qr-code";
import { useLocation, useNavigate } from "react-router-dom";
import {
  doc,
  updateDoc,
  collection,
  getDoc,
  query,
  setDoc,
} from "firebase/firestore";
import { useSuperfan } from "../../../index";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import SUPERFANLOGO from "./logo.png";
import { Padding } from "../../component/index";
// random 5 digit number mixed with alphabets

const Qr = () => {
  const { db , rewardToken , collection , user } = useSuperfan();
  const nav = useNavigate();

  return (
    <div
      style={{
        width: "90svw",
        height: "125svw",
        maxWidth: "43rem",
        maxHeight: "60rem",
        zIndex: "100",
        opacity: "1",
        position : "relative",
      }}
      className="superfanWraper superfanQR"
    >
      <motion.div
        style={{
          background: "black",
          height: "100%",
          width: "100%",
          borderRadius: "15px",
          padding: "0 1.5rem",
        }}
        className="superfanQR"
      >
        <div className="block" style={{ height: "10%" }} />
        
        <div
          className="imgContainer-h"
          style={{height : "1.2rem", position : "relative", display : "flex", width : "100%"}}
        >
          <img src = {SUPERFANLOGO} style={{height : "100%"}}/>
          <Back />
        </div>

    
        <div style={{ height: "1.5%" }} />

        <p
          style={{
            fontSize: "0.7rem",
            fontWeight: "500",
            lineHeight: "1",
            textAlign: "left",
            margin: "0 10%",
            color: "white",
          }}
        >
          Please present this QR code to
          <br />
          our registration staff to scan.
        </p>

        <div style={{ height: "8%" }} />

        <div
          style={{
            height: "40%",
            width : "65%",
            maxHeight: "20rem",
            position: "relative",
            margin: "auto",
          }}
        >
          {rewardToken && (
            <QRCode
              title="QR GENERATOR"
              value={rewardToken}
              style={{
                border: " 20px white solid",
                background: "white",
                // height: "33svh",
                // width: "33svh",
                // maxWidth: "16rem",
                // maxHeight: "16rem",
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </div>

        <div style={{ height: "8%" }} />

        <div
          className="block"
          style={{
            borderBottom: "3px #00bf63 solid",
          }}
        />

        <div style={{ height: "5%" }} className="block" />

        <Footer style = {{
          color : "green",
        }} />
        
      </motion.div>
    </div>
  );
};

const Footer = ({...style}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "left",
      }}
      className="superfanFooter"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          textAlign: "left",
        }}
      >
        <p style={{textAlign:'left', fontSize: "1rem", fontWeight: "800", color:'#9A9A9A' }}>
          SUPERFAN™
        </p>

        <p
          style={{
            fontSize: "0.5rem",
            whiteSpace: "nowrap",
            fontWeight: "500",
            margin: "0",
            color:'#9A9A9A'
          }}
        >
          ® All rights reserved by <a style={{color:'#9A9A9A', textDecoration:'underline'}}>
          <span
          style={{
            textDecoration: "underline",
            pointerEvents: "auto",
            cursor: "pointer",
            color:'#9A9A9A'
          }}
          onClick={() => {
            window.open(
              "https://linktr.ee/conten.t?lt_utm_source=lt_share_link#379578560",
              "_blank"
            );
          }}
        >
            SUPERFAN
            </span>
            </a>.
        </p>
      </div>
    </div>
  );
};

const Back = () => {
  const { setRewardToken } = useSuperfan();
  return (
    <div
      style={{
        height: "5svh",
        maxHeight: "2rem",
        position: "absolute",
        right: "1rem",
        zIndex: "100",
      }}
      onClick={() => {
        setRewardToken(null);
      }}
    >
      <svg
        style={{ height: "100%" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <circle cx="12" cy="12" r="11" stroke="white" strokeWidth={1.2} />
        <path
          fill="white"
          d="M19 11H7.14l3.63-4.36a1 1 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13A1 1 0 0 0 4 12a1 1 0 0 0 .07.36c0 .05 0 .08.07.13a1.19 1.19 0 0 0 .09.15l5 6A1 1 0 0 0 10 19a1 1 0 0 0 .64-.23 1 1 0 0 0 .13-1.41L7.14 13H19a1 1 0 0 0 0-2z"
        />
      </svg>
    </div>
  );
};

export default Qr;
