import { Button, Wraper } from "./components";
import { useNavigate } from "react-router-dom";
import Footer from "./components/Footer";
import { useSuperfan } from "../superfancontext";
import { useEffect, useMemo, useRef, useState } from "react";
import PopUp from "./components/PopUp";
import StampAnimation2 from "./components/StampAnimation2";
import Lottie from "react-lottie";
import LoadingAnimation from "./components/lotties/loading.json";

const Index = () => {
  const navigate = useNavigate();
  const sectionRef = useRef();
  const { userMetaData, declineRSVP, user, acceptRSVP } = useSuperfan();
  const [isRsvp, setIsRsvp] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const loadingOption = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    console.log("userMetaData", userMetaData);
    console.log("rsvp", userMetaData?.rsvp);
    if (userMetaData?.rsvp === true || userMetaData?.rsvp === false) {
      setIsLoading(false); // Stop loading when userMetaData is available
    }
  }, [userMetaData?.rsvp]);

  return (
    <Wraper>
      {isLoading ? (
        <div
          style={{
            textAlign: "center",
            marginTop: "80%",
          }}
        >
          {/* <p>Loading leaderboard...</p> */}
          <Lottie options={loadingOption} height={100} width={100} />
        </div>
      ) : (
        <section
          style={{
            opacity: userMetaData ? "1" : "0",
            transition: "opacity 1s",
          }}
        >
          <div className="block" style={{ height: "4svh" }} />
          <div
            style={{
              width: "90%",
              margin: "0 auto",
            }}
          >
            <div
              style={{
                width: "22svh",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src="/webp/logo.webp"
                alt="coachLogo"
              />
            </div>

            <div className="block" style={{ height: "1svh" }} />
            <h1
              style={{
                textAlign: "left",
                //fontSize: "2.1rem",
                fontSize: "5svh",
                whiteSpace: "nowrap",
              }}
            >
              YOU'RE ON THE LIST
            </h1>
            <div className="block" style={{ height: "0svh" }} />
            <p
              style={{
                textAlign: "left",
                fontSize: "1.80svh",
                //marginLeft: "1.5rem",
                display: userMetaData?.rsvp
                  ? "block"
                  : isRsvp
                  ? "block"
                  : "none",
              }}
            >
              Congrats, you're going to Coach Play APW! <br /> As one of the
              first 500 sign-ups, you're getting VIP perks. You will receive an
              email or a WhatsApp message with confirmation.
            </p>
            <p
              style={{
                textAlign: "left",
                fontSize: "1.95svh",
                display: userMetaData?.rsvp
                  ? "none"
                  : isRsvp
                  ? "none"
                  : "block",
              }}
            >
              Congrats, you're going to Coach Play APW! <br /> You will receive
              an email or a WhatsApp message with your confirmation.
            </p>
          </div>
          <div className="block" style={{ height: "3svh" }} />

          <div
            style={{
              position: "relative",
              height: "28svh",
              width: "70%",
              display: "flex",
              justifyContent: "end",
              flexDirection: "column",
              margin: "0 auto",
            }}
          >
            <div
              className="floating"
              style={{
                position: "relative",
                height: "28svh",
                margin: "auto",
              }}
            >
              <img
                src="/webp/stamp.webp"
                alt="float"
                style={{
                  height: "100%",
                  transform: "rotate(10deg)",
                }}
              />
            </div>

            <div>
              <div
                style={{
                  height: "15svh",
                  width: "15svh",
                  position: "absolute",
                  right: "20%",
                  bottom: "20%",
                  zIndex: "1",
                  transform: "translate(50%, 50%)",
                }}
              >
                <img
                  src={
                    userMetaData?.rsvp
                      ? "/png/ExclusivePass.png"
                      : isRsvp
                      ? "/png/ExclusivePass.png"
                      : "/png/GeneralPass.png"
                  }
                  alt="pass"
                  style={{
                    //position: "absolute",
                    width: "100%",
                    height: "100%",
                    transform: userMetaData?.rsvp
                      ? "rotate(15deg) scale(0.85)"
                      : isRsvp
                      ? "rotate(15deg) scale(0.85)"
                      : "rotate(9deg) scale(0.85)",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="block" style={{ height: "3svh" }} />
          <div
            style={{
              textAlign: "center",
              width: "90%",
              margin: "0 auto",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.95svh",
              }}
            >
              {userMetaData?.selectedDate
                ? `See you on ${userMetaData.selectedDate}`
                : "Open to public 8-10 November."}
            </p>
            <div className="block" style={{ height: "1svh" }} />
            {/* <p
              style={{
                fontWeight: "bold",
                fontSize: "1.95svh",
              }}
            >
              Coach Play APW
            </p> */}
            <p
              style={{
                fontSize: "1.95svh",
              }}
            >
              <span
              style={{fontWeight: "bold",}}
              > Coach Play APW </span><br/>
              C-01, 29, Jalan Riong <br /> 59100, Kuala Lumpur, Malaysia
            </p>
          </div>
          <div className="block" style={{ height: "1svh" }} />
          <span
            style={{
              width: "28svh",
              height: "7svh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
            onClick={() => {
              window.open(
                "https://malaysia.coach.com/coach-play-apw",
                "_blank"
              );
            }}
          >
            <Button
              style={{
                width: "28svh",
                height: "7svh",
                fontSize: "1.8svh",
              }}
            >
              ABOUT <br />
              COACH PLAY APW
            </Button>
          </span>
          <div className="block" style={{ height: "1svh" }} />
          <Button
            style={{
              width: "28svh",
              height: "7svh",
              fontSize: "1.8svh",
            }}
            onClick={() => {
              navigate("/redemption");
            }}
          >
            REDEEM PERKS
          </Button>
          <div className="block" style={{ height: "1svh" }} />
          <Button
            style={{
              width: "28svh",
              height: "7svh",
              fontSize: "1.8svh",
            }}
            onClick={() => {
              navigate("/gameLanding");
            }}
          >
            PLAY THE GAME
          </Button>
          <div className="block" style={{ height: "1svh" }} />
          <Footer />
        </section>
      )}
    </Wraper>
  );
};

export default Index;
