import { useEffect, useState } from "react";
import OTP from "./component/otp";
import { motion } from "framer-motion";
import { Pre, CollectInfo, Padding } from "./component";
import VerifyLink from "./component/verifyurl";


const Index = ({
  afterSignInTo,
  setCanPlaySuperFan,
  canPlaySuperFan,
  setSelectedId,
  selectedId
}) => {

  return (
    <div>
      <motion.div
        style={{
          pointerEvents: canPlaySuperFan ? "auto" : "none",
          justifyContent: "center",
          textAlign: "center",
          width: "100svw",
          height: "100svh",
          overflow: "hidden",
          position: "fixed",
          top: "0",
          zIndex: "99",
        }}
        id="pppContainer"
        className="superfanWraper"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <motion.div
          style={{
            width: "100svw",
            height: "100svh",
            position: "absolute",
            left: "0",
            top: "0",
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: canPlaySuperFan ? 1 : 0,
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          onClick={(e) => {
            // setCanPlaySuperFan(false);
          }}
        />
        <motion.div
          style={{
            transition: "0.8s ease-in-out",
            borderRadius: "1rem 1rem 0 0",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%,0)",
            overflow: "scroll",
          }}
          initial={{
            bottom: "-100%",
          }}
          animate={{
            bottom: canPlaySuperFan ? "-1%" : "-101%",
          }}
        >
          <section
            style={{
              height: "fit-content",
              minHeight: "20rem",
              width: "95svw",
              position: "relative",
              maxWidth: "30rem",
              transition: "0.8s ease-in-out",
            }}
          >
            {selectedId?.includes("pre-") && (
              <HanderHeight>
                <Padding>
                  <Pre setSelectedId = {setSelectedId} selectedId = {selectedId}/>
                </Padding>
              </HanderHeight>
            )}
            {selectedId === "otp" && (
              <HanderHeight>
                <Padding>
                  <OTP 
                    setCanPlaySuperFan={setCanPlaySuperFan} 
                    setSelectedId = {setSelectedId}
                  />
                </Padding>
              </HanderHeight>
            )}
            {selectedId === "verifyurl" && (
              <HanderHeight>
                <Padding>
                  <VerifyLink />
                </Padding>
              </HanderHeight>
            )}
            {selectedId === "collect-info" && (
              <Padding>
                <CollectInfo 
                  setCanPlaySuperFan={setCanPlaySuperFan}
                  afterSignInTo = {afterSignInTo}
                />
              </Padding>
            )}
          </section>
        </motion.div>
      </motion.div>
    </div>
  );
};

const HanderHeight = ({
  children,
})=>{
  return (
    <div
      style={{
        height: "70svh",
        maxHeight: "29rem",
      }}
    >
      {children}
    </div>
  )
}

export default Index;
