export const rsvp = [
    {
      title: "EXCLUSIVE-PASS",
      buttonText: "Enter ",
      imgSrc: "/webp/ticket.webp",
      disableBlur: false,
    },
    {
      title: "COACH PLAY APW POSTERS",
      buttonText: "Claim",
      imgSrc: "/webp/poster.webp",
      disableBlur: false,
    },
    {
      title: "PHOTOBOOTH",
      buttonText: "Claim",
      imgSrc: "/webp/photobooth.webp",
      disableBlur: false,
    },
    {
      title: "THE COACH COFFEE SHOP MINI SOFT SERVE",
      buttonText: "Claim",
      imgSrc: "/webp/cafe.webp",
      disableBlur: false,
    },
    {
      title: "CLAWING MACHINE",
      buttonText: "Claim",
      imgSrc: "/webp/claw_machine.webp",
      disableBlur: false,
    },
    {
      title: "5% DISCOUNT",
      buttonText: "Claim",
      imgSrc: "/webp/discount.webp",
      disableBlur: false,
    },
  ]
  
export const noneRsvp =  [
    {
      title: "GENERAL-PASS",
      buttonText: "Enter",
      imgSrc: "/webp/ticket.webp",
      disableBlur: false,
    },
    {
      title: "COACH PLAY APW POSTERS",
      buttonText: "Claim",
      imgSrc: "/webp/poster.webp",
      disableBlur: false,
    },
    {
      title: "PHOTOBOOTH",
      buttonText: "Claim",
      imgSrc: "/webp/photobooth.webp",
      disableBlur: false,
    },
    {
      title: "5% DISCOUNT",
      buttonText: "Claim",
      imgSrc: "/webp/discount.webp",
      disableBlur: false,
    },

];
