import { Button, Wraper } from "./components";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Lottie } from "lottie-react";
import { Sound } from "phaser";
import SoundBtn from "./components/SoundBtn";

const Index = ({ setPlayType }) => {
  const navigate = useNavigate();
  const [selectedSong, setSelectedSong] = useState(null);
  const [activeImage, setActiveImage] = useState(null);
  const [particlePos, setParticlePos] = useState(null);
  const [isMuted, setIsMuted] = useState(false);


  const song1Ref = useRef(null);
  const song2Ref = useRef(null);
  const song3Ref = useRef(null);

  const handlePlay = (type) => {
    if (selectedSong === type) {
      if (type === 1) song1Ref.current.pause();
      if (type === 2) song2Ref.current.pause();
      if (type === 3) song3Ref.current.pause();

      setSelectedSong(null);
      setActiveImage(null);
      setPlayType(null);
      localStorage.removeItem("selectedSongType");
    } else {
      if (selectedSong) {
        if (selectedSong === 1) song1Ref.current.pause();
        if (selectedSong === 2) song2Ref.current.pause();
        if (selectedSong === 3) song3Ref.current.pause();
      }

      setSelectedSong(type);
      setPlayType(type);
      localStorage.setItem("selectedSongType", type);

      if (type === 1) {
        song1Ref.current.currentTime = 0;
        song1Ref.current.play();
      } else if (type === 2) {
        song2Ref.current.currentTime = 0;
        song2Ref.current.play();
      } else if (type === 3) {
        song3Ref.current.currentTime = 0;
        song3Ref.current.play();
      }

      setActiveImage(type);

      const button = document.querySelector(`#button${type}`);
      const rect = button.getBoundingClientRect();
      setParticlePos({
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
      });
    }
  };

  const playSelectedSong = () => {
    localStorage.setItem("hasSelectedSong", true);
    if (selectedSong) {
      navigate("/game");
    } else {
      alert("Please select a song first.");
    }
  };

  const handleToggleSound = (isSoundOn) => {
    setIsMuted(!isSoundOn);
    song1Ref.current.muted = !isSoundOn;
    song2Ref.current.muted = !isSoundOn;
    song3Ref.current.muted = !isSoundOn;
    // if (isSoundOn) {
    //   song1Ref.current.muted = true;
    //   song2Ref.current.muted = true;
    //   song3Ref.current.muted = true;
    // } else {
    //   song1Ref.current.muted = false;
    //   song2Ref.current.muted = false;
    //   song3Ref.current.muted = false;
    // }
  };

  useEffect(() => {
    const pauseAudio = () => {
      if (selectedSong === 1) song1Ref.current.pause();
      if (selectedSong === 2) song2Ref.current.pause();
      if (selectedSong === 3) song3Ref.current.pause();
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        pauseAudio();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [selectedSong]);

  return (
    <Wraper style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          height: "10svh",
        }}
      ></div>

      <div className="block" style={{ height: "5svh" }} />
      <div className="imgContainer" style={{ height: "2.5svh" }}>
        <img src="/webp/logo.webp" alt="coachLogo" />
      </div>
      <div className="block" style={{ height: "1svh" }} />
      <h1>PLAY IT YOUR WAY</h1>
      <p>Pick your favourite audio track.</p>
      <div className="block" style={{ height: "5svh" }} />

      <div className="block">
        <motion.div
          id="button1"
          animate={activeImage === 1 ? { scale: [1, 1.2, 1] } : {}}
          transition={{
            duration: 1.5,
            repeat: activeImage === 1 ? Infinity : 0,
          }}
          style={{
            //display: "inline-block",
            height: "20svh",
            width: "45svw",
            position: "absolute",
            top: "21%",
            left: "5%",
          }}
          onClick={() => handlePlay(1)}
        >
          <img
            src={activeImage === 1 ? "png/C1_fill.png" : "png/C1.png"}
            alt="button1"
            style={{
              height: "100%",
              width: "100%",
              transition: "opacity 500ms ease-in-out",
            }}
          />
        </motion.div>

        <motion.div
          id="button2"
          animate={activeImage === 2 ? { scale: [1, 1.2, 1] } : {}}
          transition={{
            duration: 1.5,
            repeat: activeImage === 2 ? Infinity : 0,
          }}
          style={{
            //display: "inline-block",
            height: "20svh",
            width: "45svw",
            position: "absolute",
            top: "38%",
            right: "4%",
          }}
          onClick={() => handlePlay(2)}
        >
          <img
            src={activeImage === 2 ? "png/C2_fill.png" : "png/C2.png"}
            alt="button2"
            style={{ height: "100%", width: "100%" }}
          />
        </motion.div>

        <motion.div
          id="button3"
          animate={activeImage === 3 ? { scale: [1, 1.2, 1] } : {}}
          transition={{
            duration: 1.5,
            repeat: activeImage === 3 ? Infinity : 0,
          }}
          style={{
            //display: "inline-block",
            height: "20svh",
            width: "45svw",
            position: "absolute",
            top: "56%",
            left: "10%",
          }}
          onClick={() => handlePlay(3)}
        >
          <img
            src={activeImage === 3 ? "png/C3_fill.png" : "png/C3.png"}
            alt="button3"
            style={{ height: "100%", width: "100%" }}
          />
        </motion.div>

        <div className="block" style={{ height: "1svh" }} />
      </div>
      <div className="block" style={{ height: "57svh" }} />
      <Button
        onClick={()=> {playSelectedSong()}}
        style={{

          opacity: selectedSong ? "1" : "0",
          pointerEvents: selectedSong ? "auto" : "none",
        }}
      >
        PLAY
      </Button>
      <div className="block" style={{ height: "0svh" }} />

      <img
        src="/webp/tile.webp"
        alt="coachLogo"
        style={{
          position: "absolute",
          width: "35svw",
          right: "15%",
          bottom: "5%",
          transform: "translate(50%, 50%)",
          zIndex: 1,
        }}
      />
      {/* <div
        style={{
          position: "absolute",
          right: "5%",
          top: "4%",
          zIndex: "2",
          width: "8svw",
          height: "auto",
        }}
      >
        <SoundBtn onToggleSound={handleToggleSound} />
      </div> */}

      <audio ref={song1Ref} src="/mp3/song1_Music.mp3" />
      <audio ref={song2Ref} src="/mp3/song2_Music.mp3" />
      <audio ref={song3Ref} src="/mp3/song3_Music.mp3" />
    </Wraper>
  );
};

export default Index;
