import {
  Input,
  Button,
  InputMobile,
  InputEmail,
  InputEmailOri,
  InputMobileOri,
  AnimateBox,
} from "../common";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { makeUseVisualState, motion } from "framer-motion";
import SUPERFANLOGO from "./logo.png";
import GoogleImg from "./google_icon.png";
import toast from "react-hot-toast";
import { useSuperfan } from "../../../superfancontext";
import { httpsCallable } from "firebase/functions";
import { setDoc, doc, onSnapshot } from "firebase/firestore";
import { sendVerificationLink, db } from "../../firebase";


export const Padding = ({ children }) => {
  return (
    <div
      style={{
        padding: "0 0rem",
        backgroundColor: "black",
        height: "100%",
      }}
    >
      {children}
    </div>
  );
};

export const Pre = (props) => {
  const { selectedId, setSelectedId } = props;
  const { googleSignIn, collection } = useSuperfan();
  const nav = useNavigate();
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  const singaporeMalaysiaMobileRegex = /^\+(65|60)\s?[0-9]{8,10}$/;
  const { twillioOTP } = useSuperfan();
  const [sendingOTP, setSendingOTP] = useState(false);
  const [googleLogin, setGoogleLogin] = useState(false);
  const valueRef = useRef(null);

  const handerSend = async () => {
    await sendVerificationLink({
      phone: valueRef.current,
    });
  };

  const handerClick = async () => {
    let emailOrMobile = valueRef.current;
    setSendingOTP(true);
    if (selectedId === "pre-email") {
      if (emailRegex.test(emailOrMobile)) {
        try {
          await twillioOTP();
          setSendingOTP(false);
          setSelectedId("otp");
        } catch (e) {
          setSendingOTP(false);
          alert("Error");
        }
      } else {
        toast.error("Invalid Email");
        setSendingOTP(false);
      }
    } else {
      if (singaporeMalaysiaMobileRegex.test(emailOrMobile)) {
        try {
          await handerSend();
          setSendingOTP(false);
          setSelectedId("verifyurl");
        } catch (e) {
          setSendingOTP(false);
          alert("Error");
        }
      } else {
        toast.error("Invalid Mobile Number");
        setSendingOTP(false);
      }
    }
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        delay: 1.2,
        duration: 0.8,
      }}
      style={{ height: "100%", padding: "0 2.5rem" }}
    >
      <div className="block" style={{ height: "2.2rem" }} />
      <div className="imgContainer-h" style={{ height: "1.2rem" }}>
        <img src={SUPERFANLOGO} />
      </div>

      <div className="block" style={{ height: "0.9rem" }} />

      <p style={{ textAlign: "left" }}>
        Please sign-up or sign-in below
        <br />
        to enter experience.
      </p>

      <div className="block" style={{ height: "2rem" }} />
      {/*  */}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h5>{selectedId === "pre-mobile" ? "Mobile" : "Email"}</h5>
        <h5
          //71 76 80
          style={{ color: "rgb(154 154 154)" }}
          onClick={() => {
            setSelectedId((pre) => {
              if (pre === "pre-email") {
                return "pre-mobile";
              } else {
                return "pre-email";
              }
            });
          }}
        >
          {selectedId === "pre-mobile" ? "Use Email" : "Use Mobile"}
        </h5>
      </div>

      <div className="block" style={{ height: "1rem" }} />

      {selectedId === "pre-email" && <InputEmailOri valueRef={valueRef} />}
      {selectedId === "pre-mobile" && <InputMobileOri valueRef={valueRef} />}

      <div className="block" style={{ height: "2rem" }} />

      <Button
        id="2"
        style={{
          backgroundColor: "#00BF63",
          color: "#000",
          position: "relative",
          overflow: "hidden",
        }}
        name="Login/Sign-up"
        onClick={async () => {
          // await handerClick();
          await handerClick();
        }}
      >
        {sendingOTP ? <AnimateBox /> : "Login/Sign-up"}
        {/* <AnimateBox/> */}
      </Button>

      <div className="block" style={{ height: "1.2rem" }} />

      <Button
        id="3"
        style={{
          backgroundColor: "#141617",
          color: "white",
        }}
        name="Sign in with Google"
        onClick={async () => {
          googleSignIn(nav);
          setGoogleLogin(true);
        }}
      >
        <div
          style={{
            display: "flex",
            margin: "0 auto",
            width: "fit-content",
            alignItems: "center",
          }}
        >
          {googleLogin && <AnimateBox />}
          {!googleLogin && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="imgContainer-h" style={{ height: "1.2rem" }}>
                <img src={GoogleImg} />
              </div>
              <div style={{ width: "0.6rem" }} />
              Sign in with Google
            </div>
          )}
        </div>
      </Button>

      <h6
        style={{
          position: "absolute",
          bottom: "2rem",
          left: "50%",
          transform: "translate(-50%,0)",
          color: "#505050",
          whiteSpace: "nowrap",
        }}
        onClick={() => {
          // SUPERFANPrivacyPolicy.pdf
          window.open(
            "https://doc.superfan.digital/privacypolicy-superfan.pdf",
            "_blank"
          );
        }}
      >
        Privacy Policy | Terms of use
      </h6>
    </motion.div>
  );
};

export const CollectInfo = ({ setCanPlaySuperFan, afterSignInTo }) => {
  const { addInfo, collection } = useSuperfan();
  const [leftInfo, setLeftInfo] = useState(false);
  const [info, setInfo] = useState({});
  const inputRef = useRef(null);
  const { functions, user } = useSuperfan();
  const nav = useNavigate();
  const getLeftInfo = httpsCallable(functions, "getleftinfo");
  const [processing, setProcessing] = useState(false);


  useEffect(() => {
    if (user?.isAnonymous === false) {
      let userCollection = "users-" + collection.split("-")[0];
      let userRef = doc(db, userCollection, user.uid);

      onSnapshot(userRef, (doc) => {
        console.log(doc.data());
        
        setProcessing(false);
        let _temp = {};
        let requireInfoTemp = {
          name: false,
          email: false,
          phoneNumber: false,
          term: false,
        };
        
        try{
          Object.keys(doc.data()).forEach((key) => {
            _temp[key] = true;
            requireInfoTemp[key] = true;
          });  
        }
        catch(e){}

        let isAll = Object.keys(requireInfoTemp).every((key) => {
          return requireInfoTemp[key] === true;
        });

        
        if (isAll) {
          try{
            afterSignInTo.callBack();
            nav(afterSignInTo.to);
            setCanPlaySuperFan(false);
            setLeftInfo(false);
          }
          catch(e){}
        }
        else{
          setCanPlaySuperFan(true);
          setLeftInfo(true);
          let collectInfoContainer = document.getElementById(
            "collectInfoContainer"
          );
          collectInfoContainer.style.height = "100%";
          collectInfoContainer.style.maxHeight = "40rem";
        }

        Object.keys(requireInfoTemp).forEach((key) => {
          let el = document.getElementById(key);

          if(requireInfoTemp[key] === true){
            if(el){
              el.style.display = "none";
            }
          }
          else{
            if(el){
              el.style.display = "block";
            }
          }
        });
      });
    }
  }, [user]);

  return (
    <div
      id="collectInfoContainer"
      style={{
        height: "70svh",
        padding: "0 2.5rem",
        maxHeight: "29rem",
        transition: "0.8s ease-in-out",
        opacity: leftInfo ? 1 : 0,
        transitionDuration: "1.2s",
      }}
    >
      <div className="block" style={{ height: "2.2rem" }} />
      <div className="imgContainer-h" style={{ height: "1.2rem" }}>
        <img src={SUPERFANLOGO} />
      </div>

      <div className="block" style={{ height: "1.5rem" }} />

      <p style={{ textAlign: "left" }}>Please sign-up or sign-in below.</p>
      <div className="block" style={{ height: "1rem" }} />
      <Name setInfo={setInfo} />
      <Emaill setInfo={setInfo} />
      <Mobilee setInfo={setInfo} />
      <Terms setInfo={setInfo} info={info} />
      <div className="block" style={{ height: "1.5rem" }} />

      <Button
        id="2"
        style={{
          backgroundColor: "#00BF63",
          color: "#000",
        }}
        onClick={async () => {
          if (info?.term === false) {
            return toast.error("Please accept the terms");
          }

          if (info?.phoneNumber) {
            const singaporeMalaysiaMobileRegex = /^\+(65|60)\s?[0-9]{8,10}$/;
            if (!singaporeMalaysiaMobileRegex.test(info.phoneNumber)) {
              return toast.error("Invalid Phone Number");
            }
          }

          if (info?.email) {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if (!emailRegex.test(info.email)) {
              // if false
              return toast.error("Invalid Email");
            }
          }
          setCanPlaySuperFan(false);
          setProcessing(true);

          try {
            afterSignInTo.callBack();
            if(afterSignInTo.to){
              nav(afterSignInTo.to);
            }
          } catch (e) {}

          setTimeout(() => {
            addInfo(info);
          }, 1000);
        }}
      >
        {processing ? <AnimateBox /> : "Enter"}
      </Button>
      <div className="block" style={{ height: "3rem" }} />
    </div>
  );
};

const Emaill = ({ setInfo }) => {
  return (
    <div id="email">
      <h5 style={{ color: "white", textAlign: "left" }}>Email</h5>
      <div className="block" style={{ height: "0.5rem" }} />
      <InputEmail
        onChange={(e) => {
          setInfo((pre) => {
            return {
              ...pre,
              email: e.target.value,
            };
          });
        }}
      />
      <div className="block" style={{ height: "1rem" }} />
    </div>
  );
};

const Mobilee = ({ setInfo }) => {
  return (
    <div
      id="phoneNumber"
    >
      <h5 style={{ color: "white", textAlign: "left" }}>Mobile</h5>
      <div className="block" style={{ height: "0.5rem" }} />
      <InputMobile setInfo={setInfo} />
      <div className="block" style={{ height: "1rem" }} />
    </div>
  );
};

const Name = ({ setInfo }) => {
  return (
    <div
      id="name"
    >
      <h5 style={{ color: "white", textAlign: "left" }}>Name</h5>
      <div className="block" style={{ height: "0.5rem" }} />
      <Input
        placeholder={"Name"}
        type="text"
        onChange={(e) => {
          setInfo((pre) => {
            return {
              ...pre,
              name: e.target.value,
            };
          });
        }}
      />
      <div className="block" style={{ height: "1rem" }} />
    </div>
  );
};

export function Terms({ setInfo, info }) {
  return (
    <div
      id="term"
    >
      <div className="block" style={{ height: "0.5rem" }} />
      <section
        style={{
          display: "flex",
        }}
        className="terms dontwantBold"
        id="superfanTerms"
      >
        <div
          onClick={() => {
            setInfo((pre) => {
              return {
                ...pre,
                term: !info?.term,
              };
            });
          }}
        >
          <div
            id="terms"
            style={{
              width: "0.8rem",
              height: "0.8rem",
              backgroundColor: "#fff",
              borderRadius: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
              marginRight: "1rem",
            }}
          >
            <div
              style={{
                width: "0.5rem",
                height: "0.5rem",
                backgroundColor: !info?.term ? "#fff" : "#00BF63",
                borderRadius: 0,
                border: "none",
              }}
            />
          </div>
        </div>

        <div>
          <p
            style={{
              color: "white",
              fontSize: "1rem",
              textAlign: "left",
              lineHeight: "1.2",
              letterSpacing: "0.1rem",
            }}
          >
            By submitting your personal details, you are consenting to your
            personal data being collected and processed by COACH and its
            affiliated companies for the prize redemption verification purposes.
            For more data enquiries please contact us{" "}
            <span
              style={{
                textDecoration: "underline",
                fontSize: "0.5rem !important",
              }}
              onClick={() => {
                window.open(
                  "https://malaysia.coach.com/security-and-privacy",
                  "_blank"
                );
              }}
            >
              Privacy Policy.
            </span>
          </p>
        </div>
      </section>
    </div>
  );
}
