import { motion } from "framer-motion";
import { useRef, useState } from "react";
import SUPERFANLOGO from "./logo.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import loadingJson from "./loadingGreen.json";
import Lottie from 'react-lottie';
import { text } from "framer-motion/client";

const VerifyLink = ({ style }) => {
  const [counter, setCounter] = useState(30);
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    if (counter === 0) {
      clearInterval(timer);
      window.location.reload();
    }

    return () => clearInterval(timer);
  }, [counter, navigate]);

  return (
    <motion.div
      style={{
        backgroundColor: "transparent",
        textAlign: "left",
        height: "100%",
        width: "100%",
        padding: "0 2.5rem",
        ...style,
      }}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        delay: 0.5,
        duration: 0.8,
      }}
    >
      <div className="block" style={{ height: "2.2rem" }} />

      <div className="imgContainer-h" style={{ height: "1.2rem" }}>
        <img src={SUPERFANLOGO} />
      </div>

      <div className="block" style={{ height: "7rem" }} />
      <h7
      style={{
        fontFamily: "Poppins-Medium",
        textAlign: "center",
        fontSize: "3svh",
        display: "block",

      }}
      >Verification link sent!</h7>
      <div className="block" style={{ height: "0.5rem" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h7
          style={{
            color: "white",
            fontFamily: "Poppins-Regular",
            textAlign: "center",
            display: "inline-block",
            marginLeft: "0.5rem",
            fontSize: "2svh",
          }}
        >
          Check your Whatsapp
        </h7>
        <img
          src="/png/whatsapp.png"
          alt="whatsapp"
          style={{
            width: "1.3rem",
            height: "1.3rem",
            verticalAlign: "middle",
            display: "inline-block",
            marginLeft: "0.5rem",
          }}
        />
      </div>
      <div className="block" style={{ height: "1rem" }} />
      <p1
        style={{
          color: "#00BF63",
          textAlign: "center",
          fontSize: "2svh",
          display: "block",
        }}
      >
        Retry in {counter} seconds...
      </p1>
      <div
        className="lottieContainerGreen"
        style={{
          width: "5rem",
          height: "5rem",
          position: "relative",
          // top: "65%",
          // left: "50%",
          // transform: "translate(-50%,-50%)",
          margin: "auto",
          opacity: 1,
          pointerEvents: "none",
        }}
      >
        <Lottie options={defaultOptions} width={"100%"} height={"100%"} />
      </div>

      <h6
        style={{
          position: "absolute",
          bottom: "2rem",
          left: "50%",
          transform: "translate(-50%,0)",
          color: "#505050",
          whiteSpace: 'nowrap',
        }}
        onClick={() => {
          // SUPERFANPrivacyPolicy.pdf
          window.open(
            "https://doc.superfan.digital/privacypolicy-superfan.pdf",
            "_blank"
          );
        }}
      >
        Privacy Policy | Terms of use
      </h6>
    </motion.div>
  );
};

export default VerifyLink;
